import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import kebabCase from "lodash/kebabCase"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const MarkerHeader = styled.h3`
  display: inline;
  border-radius: 1em 0 1em 0;
  margin-bottom: 10px;
  background-image: linear-gradient(
    -100deg,
    rgba(254, 247, 79, 0.15),
    rgba(254, 247, 79, 0.8) 100%,
    rgba(254, 247, 79, 0.25)
  );
`

const MarkdownContent = styled.div`
  a {
    text-decoration: none;
    position: relative;
  }

  a::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 70%;
    left: -0.1px;
    right: -0.1px;
    bottom: 0;
    transition: top 0.1s ease-in-out;
    background-color: rgba(254, 247, 79, 0.8);
  }

  a:hover::after {
    top: 0;
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Glossary" />
      <Content>
        <h1>Glossary</h1>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} id={kebabCase(node.frontmatter.entry)}>
            <MarkerHeader>{node.frontmatter.entry}</MarkerHeader>
            <p>
              <MarkdownContent dangerouslySetInnerHTML={{ __html: node.html }} />
            </p>
          </div>
        ))}
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(glossary)/"  }}, sort: { fields: [frontmatter___entry], order: ASC }) {
      edges {
        node {
          id
          html
          frontmatter {
            entry
          }
        }
      }
    }
  }
`
